import request from 'utils/request';

const api = {
  AMS_BUNDLE_PAGE: '/api/admin/v3/bundle/page',
  AMS_BUNDLE_DEL: '/api/admin/v3/bundle/delete',
  AMS_BUNDLE_ADD: '/api/admin/v3/bundle/add',
};

// 分页
export function getAmsBundlePage(query) {
  return request({
    url: api.AMS_BUNDLE_PAGE,
    method: 'get',
    params: query,
  });
}
// 删除
export function handleAmsBundleDel(query) {
  return request({
    url: api.AMS_BUNDLE_DEL,
    method: 'get',
    params: query,
  });
}

// 添加
export function handleAmsBundleAdd(data) {
  return request({
    url: api.AMS_BUNDLE_ADD,
    method: 'post',
    data,
  });
}
