import {
  getAmsBundlePage,
  handleAmsBundleDel,
  handleAmsBundleAdd,
} from '@/api/DDJ/v3/amsBundle.js';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {},
      list: [],
      loading: {
        list: false,
      },
      postData: {},
      dialogFormVisible: false,
      dialogFormTitle: '添加',
      pages: {
        pageNum: 1,
        pageSize: 15,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {
        appId: [{ required: true, message: '请输入appId', trigger: 'blur' }],
        bundle: [{ required: true, message: '请输入bundle', trigger: 'blur' }],
      },
    };
  },
  mounted() {
    this.filterFun();
  },
  computed: {},
  methods: {
    // 获取列表
    filterFun(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      getAmsBundlePage(query).then((res) => {
        let data = res.result;
        this.list = data.records;
        this.pagesTotal = data.total;
        this.isShowPage = true;
        this.loading.list = false;
      });
    },
    // 删除操作
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          handleAmsBundleDel({
            id: id,
          }).then((res) => {
            console.log(res);
            this.filterFun();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 添加路由
    add() {
      this.dialogFormVisible = true;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          handleAmsBundleAdd(this.postData).then((res) => {
            this.$message.success(res.message);
            this.dialogFormVisible = false;
            this.filterFun();
            this.$refs['postData'].resetFields();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel() {
      this.$refs['postData'].resetFields();
      this.dialogFormVisible = false;
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.filterFun();
    },
  },
};
