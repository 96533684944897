<template>
  <div class="ddj-advertiser">
    <!-- <div class="main-Title bgff"><h2>AMS-bundle</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="70px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-form-item label="appId:">
                <el-input
                  v-model="filter.appId"
                  placeholder="Please enter appId"
                  class="mr10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-form-item label="bundle:">
                <el-input
                  v-model="filter.bundle"
                  placeholder="Please enter bundle"
                  class="mr10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-button
                type="primary"
                size="mini"
                :loading="loading.list"
                @click="filterFun('filter')"
                >Search</el-button
              >
              <el-button type="success" size="mini" @click="add()">Add</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="appId" prop="appId"></el-table-column>
          <el-table-column label="bundle" prop="bundle"></el-table-column>
          <el-table-column label="添加时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialogFormTitle" :visible.sync="dialogFormVisible" width="30%">
          <el-form :model="postData" label-width="70px" ref="postData" :rules="rules">
            <el-form-item label="appId" prop="appId">
              <el-input v-model="postData.appId"></el-input>
            </el-form-item>
            <el-form-item label="bundle" prop="bundle">
              <el-input v-model="postData.bundle"></el-input>
            </el-form-item>
            <el-form-item align="right" class="mb0">
              <el-button @click="cancel()">取 消</el-button>
              <el-button type="primary" @click="submit('postData')">确 定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjAmsBundleCon from '../../../controllers/DDJ/v3/amsBundle/list';
  export default {
    name: 'ddjAmsBundle',
    ...ddjAmsBundleCon,
  };
</script>

<style></style>
